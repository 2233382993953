import fetch from 'isomorphic-unfetch';

export async function fetchHttp<JSON = any>(input: RequestInfo, init?: RequestInit): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
}

export async function fetchHttpRaw(input: RequestInfo, init?: RequestInit): Promise<Response> {
  return fetch(input, init);
}
