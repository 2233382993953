import { Box, forwardRef, ImageProps, Skeleton } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

// wrapper for Image, will use later on for optimization, like lazy loading .etc
export const Image = forwardRef<ImageProps, 'img'>((props, ref) => {
  // Workaround because Image from Chakra puts src before loading attribute and it doesn't work in Firefox
  return <Box as="img" ref={ref} {...props} />;
});

export function ImageWithMobile(props: ImageProps & { alt: string; srcSet: string }) {
  const desktopSrc = props.srcSet.replace('_mobile', '');

  return (
    <picture>
      <source srcSet={desktopSrc} media="(min-width: 48em)" />
      {/* eslint-disable-next-line */}
      <Image {...props} />
    </picture>
  );
}

export function ImageWithSkeleton(props: ImageProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && imageRef.current?.complete) {
      setLoaded(true);
    }
  }, []);

  return (
    <Skeleton isLoaded={loaded}>
      {/* eslint-disable-next-line */}
      <Image
        ref={imageRef}
        onLoad={() => {
          setLoaded(true);
        }}
        {...props}
      />
    </Skeleton>
  );
}
