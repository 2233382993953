import { Box, Button, Container, Heading } from '@chakra-ui/react';
import {
  AppLink,
  fullSizeParentStyles,
  pxToRem,
  SHARED_ROUTES,
  VideoAutoplay,
} from '@cyberkongz/ui';
import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import { useState } from 'react';

import { TheKlawCursor, TheKlawIcon } from './Icons';
import {
  FocusRectangles,
  ShowcaseThumbnail,
  SPRING_TRANSITION_SHOWCASE,
  START_TRANISTION_PARTNER,
  useBackgroundScale,
} from './Shared';

const BASE_ASSETS_URL = '/static/home/showcases/the_klaw';

type ShowcaseStage = 'start' | 'waiting4Click' | 'videoPlaying';

const IMG_WIDTH = 1440;
const IMG_HEIGHT = 768;

const START_TRANSITION = {
  ...START_TRANISTION_PARTNER,
  duration: 1.2,
};

export function ShowcaseTheKlaw() {
  const [stage, setStage] = useState<ShowcaseStage>('start');
  const { scale } = useBackgroundScale({ imageWidth: IMG_WIDTH, imageHeight: IMG_HEIGHT });
  const isVideoPlaying = stage === 'videoPlaying';

  return (
    <>
      <AnimatePresence mode="wait">
        {!isVideoPlaying ? (
          <motion.div
            key="bg"
            animate={{
              filter: ['blur(0px)', 'blur(10px)', 'blur(10px)', 'blur(0px)'],
              scale: [1, 1, 1, 1.1],
              transition: START_TRANSITION,
            }}
            exit={{ opacity: 0, transition: { delay: 0.4 } }}
            style={{
              ...(fullSizeParentStyles as MotionStyle),
              zIndex: -1,
              background: `url(${BASE_ASSETS_URL}/bg.jpg) center bottom / cover no-repeat`,
            }}
          >
            <motion.div
              initial={{ width: '0', height: '0', opacity: 1 }}
              animate={{
                width: scale.width * 365,
                height: scale.height * 365,
              }}
              transition={{ ...SPRING_TRANSITION_SHOWCASE, delay: 2, duration: 0.35 }}
              style={{
                position: 'absolute',
                left: '43%',
                top: '78%',
                transform: 'translate(-50%,-50%)',
                borderRadius: '50%',
                border: `${pxToRem(10000)} solid rgba(0, 0, 0, 0.9)`,
                boxSizing: 'content-box',
                filter: `blur(${pxToRem(25)})`,
              }}
            />
          </motion.div>
        ) : (
          <motion.div
            key="video"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              transition: { ease: [0, 0.42, 0.58, 1] },
            }}
          >
            <VideoAutoplay
              src={`${BASE_ASSETS_URL}/video.webm`}
              fallbackVideoExtension="mp4"
              sx={{
                ...fullSizeParentStyles,
                objectFit: 'cover',
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <Container>
        <AnimatePresence mode="wait">
          {stage === 'start' ? (
            <motion.div
              key="icon"
              style={{
                textAlign: 'center',
              }}
              animate={{
                opacity: [0, 1, 1, 0],
                scale: [0.7, 1, 1, 0.7],
                transition: START_TRANSITION,
              }}
              onAnimationComplete={() => setStage('waiting4Click')}
            >
              <TheKlawIcon />
            </motion.div>
          ) : null}
          {stage === 'waiting4Click' ? (
            <motion.div
              key="wait4Click"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ...SPRING_TRANSITION_SHOWCASE,
                delay: 0.35 + 0.4,
                duration: 0.4,
              }}
              exit={{ opacity: 0, transition: { delay: 0, duration: 0.4 } }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  ...SPRING_TRANSITION_SHOWCASE,
                  duration: 0.4,
                  delay: 0.35 + 0.4 + 0.4,
                }}
                style={{
                  textAlign: 'center',
                  maxWidth: '21em',
                  margin: '0 auto',
                }}
              >
                <Heading size="xs" textShadow="0px 0px 50px #FFFFFF" mb={4}>
                  click on the ticket slot to start the klaw
                </Heading>
                <TheKlawCursor />
              </motion.div>
              <FocusRectangles
                onClick={() => setStage('videoPlaying')}
                width={pxToRem(scale.width * 100)}
                height={pxToRem(scale.height * 100)}
                sxContainer={{
                  bottom: 0,
                  transform: 'translate(-125%, -90%)',
                }}
              />
            </motion.div>
          ) : null}
          {stage === 'videoPlaying' ? (
            <ShowcaseThumbnail
              src={`${BASE_ASSETS_URL}/thumbnail.png`}
              alt="Golden Ticket"
              label="Check out the klaw!"
              href={SHARED_ROUTES.KLAW}
              delay={1.4}
              customComponent={
                <Box mt={6}>
                  <AppLink href="https://www.youtube.com/watch/WNfy3ek2LqI">
                    <Button variant="displayOutline" size="sm">
                      Watch video on YouTube
                    </Button>
                  </AppLink>
                </Box>
              }
            />
          ) : null}
        </AnimatePresence>
      </Container>
    </>
  );
}
