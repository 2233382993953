import { Box, Flex, Heading, SystemStyleObject } from '@chakra-ui/react';
import { Image } from '@components/AssetWrapper';
import { AppLink, colors, LogoFullIcon, pxToRem } from '@cyberkongz/ui';
import { HTMLMotionProps, motion, MotionStyle, Transition } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

import { CollabXIcon } from './Icons';

const focusRectangleBaseStyles: SystemStyleObject = {
  position: 'absolute',
  w: 8,
  h: pxToRem(3),
  bg: 'primary',
  boxShadow: `0px 0px 15px ${colors.primary}`,
};

const focusRectangleUpStyles: SystemStyleObject = {
  ...focusRectangleBaseStyles,
  top: 0,
  left: 0,
};

const focusRectangleDownStyles: SystemStyleObject = {
  ...focusRectangleBaseStyles,
  bottom: 0,
  right: 0,
};

const focusRectangleItemWrapper: MotionStyle = {
  position: 'relative',
  height: '50%',
  width: '100%',
};

const focusRectangleTransition: Transition = {
  type: 'spring',
  stiffness: 80,
  damping: 20,
  mass: 1,
  delay: 1.5,
  repeat: Infinity,
  duration: 1.15,
};

function FocusRectangleUpItem() {
  return (
    <motion.div
      key="up"
      style={focusRectangleItemWrapper}
      animate={{ x: [0, -10, 0], y: [0, -10, 0] }}
      transition={focusRectangleTransition}
    >
      <Box sx={focusRectangleUpStyles} />
      <Box
        sx={{
          ...focusRectangleUpStyles,
          transform: `translate(${pxToRem(1)}, -50%) rotateZ(90deg)`,
          transformOrigin: 'left',
        }}
      />
    </motion.div>
  );
}

function FocusRectangleDownItem() {
  return (
    <motion.div
      key="down"
      style={focusRectangleItemWrapper}
      animate={{ x: [0, 10, 0], y: [0, 10, 0] }}
      transition={focusRectangleTransition}
    >
      <Box sx={focusRectangleDownStyles} />
      <Box
        sx={{
          ...focusRectangleDownStyles,
          transform: `translate(${pxToRem(1)}, 50%) rotateZ(90deg)`,
          transformOrigin: 'right',
        }}
      />
    </motion.div>
  );
}

export function useBackgroundScale({
  imageWidth,
  imageHeight,
}: {
  imageWidth: number;
  imageHeight: number;
}) {
  const [scale, setScale] = useState({ width: 1, height: 1 });

  useEffect(() => {
    const imageAspectRatio = imageHeight / imageWidth;

    function handleResize() {
      const { innerWidth, innerHeight } = window;
      const currentAspectRatio = innerHeight / innerWidth;

      let finalHeight;
      let finalWidth;
      if (currentAspectRatio > imageAspectRatio) {
        finalHeight = innerHeight;
        finalWidth = innerHeight / imageAspectRatio;
      } else {
        finalWidth = innerWidth;
        finalHeight = innerWidth * imageAspectRatio;
      }

      setScale({
        width: finalWidth / imageWidth,
        height: finalHeight / imageHeight,
      });
    }

    handleResize();
    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  return { scale };
}

export function FocusRectangles({
  width,
  height,
  onClick,
  sxContainer,
}: {
  width: string;
  height: string;
  onClick?: () => void;
  sxContainer?: SystemStyleObject;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        w: width,
        h: height,
        mx: 'auto',
        cursor: onClick ? 'pointer' : 'default',
        position: 'absolute',
        left: '50%',
        zIndex: onClick ? 0 : -1,
        ...sxContainer,
      }}
    >
      <FocusRectangleUpItem />
      <FocusRectangleDownItem />
    </Box>
  );
}

export function ShowcaseThumbnail({
  src,
  alt,
  label,
  href,
  delay,
  customComponent,
}: {
  src: string;
  alt: string;
  label: string;
  href: string;
  delay?: number;
  customComponent?: ReactNode;
}) {
  return (
    <motion.div
      key="thumbnail"
      initial={{ opacity: 0, x: -246 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3, delay: delay || 1, ease: [0, 0, 0.58, 1] }}
      style={{
        position: 'absolute',
        left: pxToRem(40),
        bottom: '14vh',
      }}
    >
      <Image mb={3} src={src} alt={alt} />
      <AppLink href={href} sx={{ textDecoration: 'underline' }}>
        <Heading size="xs" maxW="10em">
          {label}
        </Heading>
      </AppLink>
      {customComponent}
    </motion.div>
  );
}

export const SPRING_TRANSITION_SHOWCASE: Partial<Transition> = {
  type: 'spring',
  stiffness: 80,
  damping: 20,
  mass: 1,
};

export const START_TRANISTION_PARTNER: Transition = {
  ...SPRING_TRANSITION_SHOWCASE,
  delay: 0.1,
  duration: 1.6,
  times: [0, 0.375, 0.625, 1],
};

const ckLogoAnimateProps: HTMLMotionProps<'div'> = {
  initial: { x: pxToRem(400), opacity: 0 },
  animate: { x: [pxToRem(400), '0rem', '0rem', pxToRem(-400)], opacity: [0, 1, 1, 0] },
  transition: START_TRANISTION_PARTNER,
};

const partnerLogoAnimateProps: HTMLMotionProps<'div'> = {
  initial: { x: pxToRem(-400), opacity: 0 },
  animate: { x: [pxToRem(-400), '0rem', '0rem', pxToRem(400)], opacity: [0, 1, 1, 0] },
  transition: START_TRANISTION_PARTNER,
};

export function ShowcasePartnerLogoStart({
  onAnimationComplete,
  partnerImage,
}: {
  onAnimationComplete: () => void;
  partnerImage: ReactNode;
}) {
  return (
    <>
      <motion.div {...ckLogoAnimateProps} onAnimationComplete={onAnimationComplete}>
        <LogoFullIcon width={pxToRem(448)} height="auto" mx="auto" display="block" />
      </motion.div>
      <Flex alignItems="center" justifyContent="center" transform="translateX(5%)">
        <motion.div {...partnerLogoAnimateProps}>
          <CollabXIcon transform="translateY(-20%)" mr={-2} />
        </motion.div>
        <motion.div {...partnerLogoAnimateProps}>{partnerImage}</motion.div>
      </Flex>
    </>
  );
}

export function ShowcasePartnerLogoStatic({ partnerImage }: { partnerImage: ReactNode }) {
  return (
    <Box position="relative">
      <LogoFullIcon width="80%" height="auto" mx="auto" display="block" />
      <Flex alignItems="center" justifyContent="center" transform="translateX(5%)">
        <CollabXIcon width="30%" transform="translateY(-20%)" mr={-8} />
        <Box width="60%" transform="translateY(-20%)">
          {partnerImage}
        </Box>
      </Flex>
    </Box>
  );
}

export function ShowcasePartnersWaiting4Interaction({
  description,
  rectangles,
}: {
  description: ReactNode;
  rectangles: ReactNode;
}) {
  return (
    <motion.div
      key="waiting4Click"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ...SPRING_TRANSITION_SHOWCASE, duration: 0.4 }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ...SPRING_TRANSITION_SHOWCASE, duration: 0.4, delay: 0.4 }}
        style={{
          textAlign: 'center',
          maxWidth: '21em',
          margin: '0 auto',
        }}
      >
        {description}
      </motion.div>
      {rectangles}
    </motion.div>
  );
}
