import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  keyframes,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { Image } from '@components/AssetWrapper';
import { ButtonFullWidthMobile } from '@components/Button';
import { PartnersMarquee } from '@components/MarqueeAnimation';
import { NumberStatsSection } from '@components/NumberStatsSection';
import { StarBackground } from '@components/StarBackground';
import { YoutubeVideoWithPreviewImage } from '@components/YoutubePlayerModal';
import {
  AppLink,
  AppLinkWithArrow,
  AppSpinner,
  Card,
  EthereumIconColor,
  fetchHttp,
  GradientShadowOverlay,
  imageBackgroundCoverStyles,
  ImageWithMobile,
  LANDING_ROUTES,
  pxToRem,
  SHARED_ROUTES,
  TRANSITIONS,
  useIsClient,
  useWeb3Context,
  VideoAutoplay,
} from '@cyberkongz/ui';
import { formatCryptoBalance } from '@cyberkongz/ui-blockchain';
import { SliderNavigationRounded, SliderWrapper } from '@cyberkongz/ui-slider';
import { ExtractedPostWithAssets, getAllPosts } from '@features/blog/apiContentful';
import { DEFAULT_BLOG_REVALIDATE_PERIOD } from '@features/blog/utils';
import { WelcomeVideoSection } from '@features/homepage/WelcomeSection';
import { PageSEOTags } from '@features/seo/SEO';
import { useAPIEcosystemSummary } from '@hooks/useDataSummary';
import { useQuery } from '@tanstack/react-query';
import { InferGetStaticPropsType } from 'next';
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { SwiperSlide } from 'swiper/react';
import { useAccount } from 'wagmi';

import { APICollectionSummaryResponse } from './api/collection-summary';
import { APIEcosystemSummarySuccessResponse } from './api/ecosystem-summary';
import { APISocialsSummaryResponse, SocialsSummary } from './api/socials-summary';

function WelcomeAboutSection() {
  const { ref } = useParallax<HTMLDivElement>({
    speed: 15,
    opacity: [0, 2],
  });

  return (
    <StarBackground>
      <GradientShadowOverlay
        sx={{
          top: pxToRem(1),
          bottom: 'auto',
          height: pxToRem(48),
          transform: 'translateY(-100%)',
        }}
      />
      <Container pt={[16, 36]} pb={16} ref={ref}>
        <Box w={['calc(100% - 3)', pxToRem(338)]} mb="16" ml="3">
          <Box
            w={pxToRem(104)}
            h={pxToRem(4)}
            bg="white"
            boxShadow="0px 0px 10px #FFFFFF"
            ml="auto"
            mr="0"
          />
          <Box
            sx={{
              borderTop: 'thinWhite',
              w: '100%',
              position: 'relative',
              '&:before': {
                position: 'absolute',
                display: 'block',
                content: '""',
                w: 5,
                h: 5,
                top: 0,
                left: 0,
                borderTop: 'inherit',
                borderBottom: 'inherit',
                transform: `rotateZ(-45deg) translate(${pxToRem(-9)}, ${pxToRem(-5)})`,
              },
            }}
          />
        </Box>
        <Heading maxW="27em" mb={[6, 20]} lineHeight="base">
          Welcome to an alternate reality. where evolution takes a different route and weird apes
          <VideoAutoplay
            sx={{
              display: 'inline',
              w: pxToRem(34),
              mx: [2, 6],
              position: 'relative',
              top: pxToRem(4),
            }}
            src="/static/home/kongz.mp4"
          />
          roam the earth
        </Heading>
        <Heading variant="displaySecondary" mb={[8, 16]}>
          We are cyberkongz. <br />
          together, we are strong!
        </Heading>
        <ButtonFullWidthMobile href={LANDING_ROUTES.ABOUT}>about cyberkongz</ButtonFullWidthMobile>
      </Container>
    </StarBackground>
  );
}

const pingAnimation = keyframes`
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
`;

function TVDot({ sx }: { sx: SystemStyleObject }) {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          w: 4,
          h: 4,
          bg: 'primary',
          borderRadius: '50%',
          animation: `${pingAnimation} 1s cubic-bezier(0,0,.2,1) infinite`,
          ...sx,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          w: 4,
          h: 4,
          bg: 'primary',
          borderRadius: '50%',
          ...sx,
        }}
      />
    </>
  );
}

const COUNCIL_SECTION_LINK = `${LANDING_ROUTES.ABOUT}#council-section`;

// TO DO NICE eventually map and include here width, position .etc for desktop/mobile to have single source of truth
const TV_SECTION_ITEMS_MOBILE = [
  {
    video: '/static/home/tv/gen.mp4',
    title: 'genesis',
    titleVariant: 'displaySecondary',
    subtitle: 'Cyberkongz',
    href: LANDING_ROUTES.KOLLECTION('genesis'),
  },
  {
    video: '/static/home/tv/baby.mp4',
    title: 'baby',
    titleVariant: 'displayPrimary',
    subtitle: 'Cyberkongz',
    href: LANDING_ROUTES.KOLLECTION('baby'),
  },
  {
    video: '/static/home/tv/vx.mp4',
    title: 'vx',
    titleVariant: 'displayTertiary',
    subtitle: 'Cyberkongz',
    href: LANDING_ROUTES.KOLLECTION('vx'),
  },
  {
    video: '/static/home/tv/council.mp4',
    title: 'Meet',
    titleVariant: 'displaySecondary',
    subtitle: 'Council',
    href: COUNCIL_SECTION_LINK,
  },
];

function TVSection() {
  return (
    <Box py={20}>
      <Container>
        <Box display={['none', 'block']}>
          <Box sx={{ position: 'relative' }}>
            <Image src="/static/home/tv/section_bg.jpg" alt="Multiple tvs background" />
            <GradientShadowOverlay top />
            <GradientShadowOverlay
              sx={{
                left: 0,
                right: 'auto',
                width: '270px',
                top: 0,
                height: 'auto',
                background: 'linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%)',
              }}
            />
            <GradientShadowOverlay
              sx={{
                left: 'auto',
                right: 0,
                width: '270px',
                top: 0,
                height: 'auto',
                background: 'linear-gradient(-90deg, #000 0%, rgba(0, 0, 0, 0) 100%)',
              }}
            />
            <AppLink href={LANDING_ROUTES.KOLLECTION('genesis')}>
              <VideoAutoplay
                sx={{ position: 'absolute', bottom: '13%', left: '1%', width: '19.5%' }}
                src="/static/home/tv/gen.mp4"
                posterExtension="jpg"
              />
              <TVDot sx={{ left: '20.5%', bottom: '12%' }} />
            </AppLink>
            <AppLink href={COUNCIL_SECTION_LINK}>
              <VideoAutoplay
                sx={{ position: 'absolute', bottom: '12.5%', left: '60.85%', width: '37.5%' }}
                src="/static/home/tv/council.mp4"
                posterExtension="jpg"
              />
              <TVDot sx={{ left: '98.5%', bottom: '14.75%' }} />
            </AppLink>
            <VideoAutoplay
              sx={{ position: 'absolute', bottom: '12.15%', left: '27.75%', width: '22%' }}
              src="/static/home/tv/intro.mp4"
              posterExtension="jpg"
            />
            <AppLink href={LANDING_ROUTES.KOLLECTION('vx')}>
              <VideoAutoplay
                sx={{ position: 'absolute', bottom: '59.1%', left: '31%', width: '20.75%' }}
                src="/static/home/tv/vx.mp4"
                posterExtension="jpg"
              />
              <TVDot sx={{ left: '52%', bottom: '58.5%' }} />
            </AppLink>
            <AppLink href={LANDING_ROUTES.KOLLECTION('baby')}>
              <VideoAutoplay
                sx={{ position: 'absolute', bottom: '71.15%', left: '61.5%', width: '19%' }}
                src="/static/home/tv/baby.mp4"
                posterExtension="jpg"
              />
              <VideoAutoplay
                sx={{ position: 'absolute', bottom: '59.5%', left: '62.75%', width: '8.7%' }}
                src="/static/home/tv/incubator.webm"
                posterExtension="png"
              />
              <VideoAutoplay
                sx={{ position: 'absolute', bottom: '59.5%', left: '71.75%', width: '8.7%' }}
                src="/static/home/tv/incubator.webm"
                posterExtension="png"
              />
              <TVDot sx={{ left: '80.75%', bottom: '59.25%' }} />
            </AppLink>
          </Box>
        </Box>
        <Box display={['block', 'none']}>
          <SliderWrapper
            sx={{
              '.swiper': {
                overflow: 'visible',
              },

              '.swiper-slide .description': {
                opacity: 0,
                transition: TRANSITIONS.standard,
              },

              '.swiper-slide-active .description': {
                opacity: 1,
              },
            }}
            slidesPerView={1.25}
            spaceBetween={20}
          >
            {TV_SECTION_ITEMS_MOBILE.map(({ video, title, titleVariant, subtitle, href }) => (
              <SwiperSlide key={title}>
                {video.includes('.png') ? (
                  <Image src={video} alt="CK logo on screen" />
                ) : (
                  <VideoAutoplay src={video} posterExtension="jpg" />
                )}
                <Box className="description">
                  <Heading size="sm" variant={titleVariant} sx={{ mt: 3 }}>
                    {title}
                  </Heading>
                  <Heading size="xs" sx={{ mb: 6 }}>
                    {subtitle}
                  </Heading>
                  <AppLink
                    href={href}
                    sx={{ textTransform: 'uppercase', textDecoration: 'underline', fontSize: 'md' }}
                  >
                    Learn More
                  </AppLink>
                </Box>
              </SwiperSlide>
            ))}
          </SliderWrapper>
        </Box>
      </Container>
    </Box>
  );
}

const PLAY_AND_KOLLECT_ITEMS = [
  {
    youtubeLink: 'https://www.youtube.com/watch/n8Q0kprkjiE',
    thumbnail: '/static/home/yt_thumbnails/play_and_kollect.jpg',
  },
  { youtubeLink: 'https://www.youtube.com/watch/Z2Vx0xwF9Uc' },
  { youtubeLink: 'https://www.youtube.com/watch/VU40FhEh2CM' },
];

function PlayAndKollectButtons() {
  const { openConnectWalletModal } = useWeb3Context();
  const { address } = useAccount();
  const isClient = useIsClient();

  return (
    <Flex sx={{ gap: 3, flexDirection: ['column', 'row'], mt: [7, 0] }}>
      {isClient && !address ? (
        <ButtonFullWidthMobile onClick={() => openConnectWalletModal()}>
          connect wallet
        </ButtonFullWidthMobile>
      ) : null}
      <ButtonFullWidthMobile href={LANDING_ROUTES.PLAY_AND_KOLLECT} variant="displayOutline">
        learn more
      </ButtonFullWidthMobile>
    </Flex>
  );
}

function PlayAndKollectSection() {
  return (
    <Box>
      <Container sx={{ py: 8, position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Box>
          <SliderWrapper
            sx={{
              '.swiper-slide': {
                transition: TRANSITIONS.standard,
                transform: 'scale(0.8)',
              },

              '.swiper-slide-active': {
                zIndex: 2,
                transform: 'scale(1.0)',
              },
            }}
            centeredSlides
            slidesPerView={1.25}
            loop
            roundLengths
            spaceBetween={1}
            breakpoints={{
              1024: {
                slidesPerView: 1.75,
              },
            }}
          >
            {PLAY_AND_KOLLECT_ITEMS.map(({ youtubeLink, thumbnail }, i) => (
              <SwiperSlide key={i}>
                <YoutubeVideoWithPreviewImage src={youtubeLink} thumbnail={thumbnail} />
              </SwiperSlide>
            ))}
            <SliderNavigationRounded />
          </SliderWrapper>
        </Box>
        <Box
          sx={{
            position: ['relative', 'absolute'],
            zIndex: 1,
            top: [0, pxToRem(50)],
            left: ['auto', undefined, '58%'],
            right: [0, undefined, 'auto'],
            order: [-1, 1],
          }}
        >
          <Heading variant="displayPrimary">PLAY & KOLLECT</Heading>
          <Heading
            size="xs"
            sx={{
              mb: [5, 8],
              mt: 1,
              color: 'textSecondary',
              maxWidth: '22.5em',
              fontSize: ['md', 'xl'],
            }}
          >
            The gamified on-chain experience to mint and collect your favorite NFTs.
          </Heading>
          <Box display={['none', 'block']}>
            <PlayAndKollectButtons />
          </Box>
        </Box>
        <Box display={['block', 'none']}>
          <PlayAndKollectButtons />
        </Box>
      </Container>
    </Box>
  );
}

function MetaverseAnimation() {
  return (
    <Box>
      <VideoAutoplay
        sx={{
          position: 'absolute',
          bottom: '41%',
          left: ['28%', '54%'],
          width: [pxToRem(164), '23%'],
        }}
        src="/static/home/metaverse/astronaut.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{
          position: 'absolute',
          bottom: '14%',
          left: [pxToRem(-40), '29%'],
          width: [pxToRem(108), '20%'],
        }}
        src="/static/home/metaverse/kongium.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{
          position: 'absolute',
          bottom: '69%',
          left: ['10%', '47%'],
          width: [pxToRem(65), '16%'],
        }}
        src="/static/home/metaverse/land.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{ position: 'absolute', bottom: '13%', left: '79%', width: [pxToRem(153), '28%'] }}
        src="/static/home/metaverse/relic.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{
          position: 'absolute',
          bottom: '32%',
          left: ['24%', '49%'],
          width: [pxToRem(51), '10%'],
          transform: 'rotate(-37deg)',
        }}
        src="/static/home/metaverse/rod.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{ position: 'absolute', bottom: '56%', left: '83%', width: [pxToRem(56), '11%'] }}
        src="/static/home/metaverse/shades.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{
          position: 'absolute',
          bottom: '69%',
          left: '71%',
          width: [pxToRem(50), '9.5%'],
          transform: 'rotate(90deg)',
        }}
        src="/static/home/metaverse/daisy.webm"
        posterExtension="png"
      />
      <VideoAutoplay
        sx={{ position: 'absolute', bottom: '23%', left: '63%', width: [pxToRem(70), '13.35%'] }}
        src="/static/home/metaverse/bunch.webm"
        posterExtension="png"
      />
    </Box>
  );
}

function MetaverseSection() {
  return (
    <Box pt={[16, 32]} mb={[-16, pxToRem(-180)]}>
      <Container>
        <Box
          sx={{
            pt: '15%',
            pb: '37.5%',
            position: 'relative',
            px: 0,
          }}
        >
          <Box sx={{ position: ['static', 'relative'], zIndex: 2 }}>
            <Heading maxW="12em" lineHeight="none">
              To the metaverse and{' '}
              <Heading
                as="span"
                variant="displaySecondary"
                sx={{
                  ml: [0, 4],
                  position: 'relative',
                  top: '0.07em',
                }}
              >
                beyond
              </Heading>
            </Heading>
            <Text
              sx={{ mt: 8, mb: [0, 8], maxW: '28.5em', fontSize: 'lg', color: 'textSecondary' }}
            >
              From launching the $BANANA token, breeding babies, enhancing Web3 security with the
              Guardian Contract, or launching a gamified minting experience under Play & Kollect.
              We&apos;ve evolved from our original Genesis Kongz into an entire ecosystem fueled by
              a passionate community.
            </Text>
            <Box
              sx={{
                position: ['absolute', 'relative'],
                bottom: ['15%', 0],
                left: 0,
                right: 0,
                zIndex: 2,
              }}
            >
              <ButtonFullWidthMobile href={LANDING_ROUTES.BLUEPRINT}>
                Blueprint
              </ButtonFullWidthMobile>
            </Box>
          </Box>
          <Box
            sx={{
              position: ['relative', 'absolute'],
              zIndex: -1,
              mt: [-8, 0],
              mb: [12, 0],
              pb: ['110.5%', 0],
              top: 0,
              left: 0,
              width: '100%',
              height: ['auto', '100%'],
            }}
          >
            <ImageWithMobile
              srcSet="/static/home/metaverse/bg_mobile.jpg"
              sx={{
                ...imageBackgroundCoverStyles,
                left: ['50%', '-10%'],
                transform: ['translateX(-50%)', 'none'],
                width: ['150%', '120%'],
                objectFit: ['cover', 'contain'],
                maxWidth: 'none',
              }}
              alt="Spaceship with earth"
            />
            <MetaverseAnimation />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

function NUMBER_STATS(
  socialsSummary?: SocialsSummary,
  data?: APIEcosystemSummarySuccessResponse['pnk']
) {
  const twitterFollowersFormatted = socialsSummary?.twitterFollowers
    ? Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
      }).format(socialsSummary?.twitterFollowers)
    : undefined;

  return [
    {
      number: twitterFollowersFormatted,
      label: 'Twitter Followers',
      href: SHARED_ROUTES.TWITTER,
    },
    {
      number: socialsSummary?.discordOnline.toLocaleString(),
      label: 'Discord Online',
      href: SHARED_ROUTES.DISCORD,
    },
    {
      number: data?.adventuresCount.toLocaleString(),
      label: 'adventure runs',
      href: `${SHARED_ROUTES.ANALYTICS}pnk`,
    },
  ];
}

// TO DO ADD HIGHEST SALES SECTION IN FUTURE
function ViewMoreRecentSales() {
  return (
    <AppLinkWithArrow sx={{ fontSize: 'md' }} href={SHARED_ROUTES.TWITTER_KONGBOT}>
      VIEW MORE
    </AppLinkWithArrow>
  );
}

function RecentSalesSection({ data }: { data?: APICollectionSummaryResponse }) {
  const isClient = useIsClient();

  return (
    <Box py={['16', '32']}>
      <Container>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: [6, 10],
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Heading size="sm">RECENT SALES</Heading>
          <Box display={['none', 'block']}>
            <ViewMoreRecentSales />
          </Box>
        </Flex>
        <SliderWrapper
          slidesPerView="auto"
          spaceBetween={19}
          sx={{
            '.swiper-slide': {
              width: pxToRem(186),
              mb: [5, 0],
            },
          }}
        >
          {isClient && data ? (
            data.collectionSales.map(
              ({
                id,
                token: { tokenId, image },
                price: {
                  amount: { raw },
                },
              }) => (
                <SwiperSlide key={id}>
                  <Card variant="landing" px={[4, 4]}>
                    <Image
                      src={image}
                      sx={{ maxHeight: pxToRem(129), mx: 'auto' }}
                      alt={`Kong ${tokenId}`}
                    />
                    <Box sx={{ fontSize: ['sm'], mt: 4, mb: 3, textAlign: 'center' }}>
                      CyberKong #{tokenId} was adopted for{' '}
                    </Box>
                    <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
                      <EthereumIconColor />
                      <Heading size="2xs" sx={{ ml: 2 }}>
                        {formatCryptoBalance(BigInt(raw), { digits: 3 })} eth
                      </Heading>
                    </Flex>
                  </Card>
                </SwiperSlide>
              )
            )
          ) : (
            <AppSpinner />
          )}
        </SliderWrapper>
        <Box display={['block', 'none']} position="relative" zIndex={1}>
          <ViewMoreRecentSales />
        </Box>
      </Container>
    </Box>
  );
}

function WhatsHappeningSection({ posts }: { posts: ExtractedPostWithAssets[] }) {
  return (
    <Box py={['16', '32']} sx={{ width: '100%' }}>
      <Container>
        <Heading
          variant="displayPrimary"
          sx={{
            textAlign: 'center',
            lineHeight: '0.6',
            mb: '-0.45em',
            position: 'relative',
            zIndex: 2,
          }}
        >
          What&apos;s
          <br />
          Happening
        </Heading>
        <SliderWrapper
          sx={{
            '.swiper-slide': {
              transition: TRANSITIONS.standard,
              transform: 'scale(0.8)',

              '.description': {
                opacity: 0,
                transition: TRANSITIONS.standard,
              },
            },

            '.swiper-slide-active': {
              zIndex: 2,
              transform: 'scale(1.0)',

              '.description': {
                opacity: 1,
              },
            },
          }}
          centeredSlides
          // workaround for Hydration UI issue with breakpoints - https://github.com/nolimits4web/swiper/issues/5784
          slidesPerView="auto"
          loop
          roundLengths
          spaceBetween={1}
          breakpoints={{
            320: {
              slidesPerView: 1.35,
            },
            768: {
              slidesPerView: 3.25,
            },
            1024: {
              slidesPerView: 4.25,
            },
          }}
        >
          {posts.map(({ title, excerpt, thumbnail, categories, slug }, i) => (
            <SwiperSlide key={i}>
              <Box sx={{ position: 'relative' }}>
                <Image src={thumbnail?.fields.file?.url} alt={thumbnail?.fields.title} />
                <Grid gap={2} mt={2}>
                  {categories ? (
                    <Box
                      sx={{
                        color: 'textTertiary',
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                        fontSize: 'sm',
                      }}
                    >
                      {categories[0].fields.title}
                    </Box>
                  ) : null}
                  <AppLink href={LANDING_ROUTES.BLOG_POST(slug)}>
                    <Heading
                      size="2xs"
                      color="neutral.100"
                      sx={{
                        overflowY: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: `3`,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {title}
                    </Heading>
                  </AppLink>
                  <Box
                    className="description"
                    color="neutral.100"
                    sx={{
                      overflowY: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: `3`,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {excerpt}
                  </Box>
                </Grid>
              </Box>
            </SwiperSlide>
          ))}
          <SliderNavigationRounded />
        </SliderWrapper>
        <Box textAlign="center" mt={['10', '16']}>
          <ButtonFullWidthMobile href={LANDING_ROUTES.BLOG}>VIEW ALL updates</ButtonFullWidthMobile>
        </Box>
      </Container>
    </Box>
  );
}

const INTO_THE_JUNGLE_ITEMS = [
  { title: 'FAQ IF YOU ARE JUST', emphasis: 'GETTING STARTED!', href: LANDING_ROUTES.FAQ },
  { title: 'QUESTIONS ABOUT', emphasis: 'PLAY & KOLLECT', href: LANDING_ROUTES.PLAY_AND_KOLLECT },
  { title: 'BE PART OF THE COMMUNITY', emphasis: 'JOIN DISCORD!', href: SHARED_ROUTES.DISCORD },
];

function IntoTheJungleSection() {
  return (
    <Box position="relative">
      <GradientShadowOverlay top />
      <Image
        src="/static/home/into_the_jungle_bg.jpg"
        sx={imageBackgroundCoverStyles}
        alt="Into the jungle grid"
      />
      <Container sx={{ pt: [14, 32], pb: [0, 32] }}>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            mb: [0, 8],
            flexDirection: ['column', 'row'],
            flexWrap: ['nowrap', 'wrap'],
          }}
        >
          <Box
            sx={{
              order: [2, 0],
              width: ['100%', pxToRem(300), 'auto'],
              mr: ['auto', '3%'],
              mt: [5, 0],
            }}
          >
            <Image
              src="/static/home/warrior_kong.png"
              alt="Warrior Kong image"
              sx={{
                mx: 'auto',
                transform: ['none', 'translateY(-3%)'],
                maxWidth: [pxToRem(190), 'none'],
              }}
            />
          </Box>
          <Box
            sx={{
              textAlign: ['center', null, 'left'],
              mb: [10, null, 0],
              flex: ['auto', 1],
              width: ['100%', 'auto'],
              maxWidth: [pxToRem(480), pxToRem(668)],
            }}
          >
            <Heading>into the jungle</Heading>
            <Heading
              variant="displaySecondary"
              sx={{
                textShadow: 'none',
                fontSize: ['5xl', '10xl'],
                mb: [4, null, 0],
              }}
            >
              !00h
            </Heading>
            <Box>
              {INTO_THE_JUNGLE_ITEMS.map(({ title, emphasis, href }) => (
                <Box key={title}>
                  <Box>
                    <Box
                      w={[pxToRem(44), pxToRem(104)]}
                      h={pxToRem(4)}
                      bg="white"
                      ml="auto"
                      mr="0"
                      boxShadow="0px 0px 10px #FFFFFF"
                    />
                    <Box borderTop="thinWhite" w="100%" />
                  </Box>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      color: 'white',
                      py: [6, 10],
                      justifyContent: ['center', 'flex-start'],
                      flexDirection: ['column', 'row'],
                    }}
                  >
                    <Text
                      sx={{
                        fontWeight: 'medium',
                        mr: [0, 4],
                        mb: [2, 0],
                        fontSize: ['sm', 'lg'],
                        letterSpacing: '0.03em',
                      }}
                    >
                      {title}{' '}
                    </Text>
                    <AppLink href={href} variant="displayHover">
                      <Heading variant="display" size="sm" letterSpacing="0.05em">
                        {emphasis}
                      </Heading>
                    </AppLink>
                  </Flex>
                </Box>
              ))}
            </Box>
          </Box>
          <PartnersMarquee />
        </Flex>
      </Container>
    </Box>
  );
}

export default function HomePage({ posts }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { data } = useQuery({
    queryKey: ['/api/collection-summary'],
    queryFn: () => fetchHttp<APICollectionSummaryResponse>('/api/collection-summary'),
    retry: false,
  });
  const { data: dataSocialsSummary } = useQuery({
    queryKey: ['/api/socials-summary'],
    queryFn: () => fetchHttp<APISocialsSummaryResponse>('/api/socials-summary'),
    retry: false,
  });
  const ecosystemSummary = useAPIEcosystemSummary();

  return (
    <Box width="100%" position="relative">
      <PageSEOTags pageKey="home" />
      <WelcomeVideoSection />
      <Box width="inherit" position="relative" zIndex={1} bg="background">
        <ParallaxProvider>
          <WelcomeAboutSection />
        </ParallaxProvider>
        <TVSection />
        <PlayAndKollectSection />
        <MetaverseSection />
        <NumberStatsSection
          numberStats={NUMBER_STATS(dataSocialsSummary?.socialsSummary, ecosystemSummary.data?.pnk)}
        />
        <StarBackground>
          <RecentSalesSection data={data} />
          <WhatsHappeningSection {...{ posts }} />
        </StarBackground>
        <IntoTheJungleSection />
      </Box>
    </Box>
  );
}

export async function getStaticProps() {
  const posts = await getAllPosts();
  const displayedPosts = posts.slice(0, 7);

  return {
    props: {
      posts: displayedPosts.concat(displayedPosts), // Workaround for loop not working in react-swiper > v8 when there are too few slides
    },
    revalidate: DEFAULT_BLOG_REVALIDATE_PERIOD,
  };
}
