import 'swiper/css/pagination';

import { Box, Button, Center, Container, Flex, Grid } from '@chakra-ui/react';
import { Image } from '@components/AssetWrapper';
import { PROJECTS_BY_ID } from '@content/partners';
import {
  APP_ROUTES_ABSOLUTE,
  AppLink,
  ArrowDownIcon,
  fullSizeParentStyles,
  GENKAI_LANDING_ROUTES_ABSOLUTE,
  GradientShadowOverlay,
  LANDING_ROUTES,
  pxToRem,
  SHARED_ROUTES,
  VideoAutoplay,
  VolumeIcon,
} from '@cyberkongz/ui';
import { SliderWrapper } from '@cyberkongz/ui-slider';
import { useUserSettings } from '@features/userSettings/UserSettingsProvider';
import { useEffect, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';

import { TheKlawIcon } from './Icons';
import { ShowcasePartnerLogoStatic } from './Shared';
import { ShowcaseAboutYou } from './ShowcaseAboutYou';
import { ShowcaseTheKlaw } from './ShowcaseTheKlaw';
import { ShowcaseUnderArmour } from './ShowcaseUnderArmour';

type WelcomSectionStage = 'start' | 'pnk' | 'genkai' | 'about_you' | 'under_armour' | 'the_klaw';

function WelcomeSectionEmaki({ muted }: { muted: boolean }) {
  return (
    <>
      <VideoAutoplay
        muted={muted}
        src="/static/home/emaki_trailer.mp4"
        posterExtension="jpg"
        sx={{
          ...fullSizeParentStyles,
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          ...fullSizeParentStyles,
          bg: 'rgba(0, 0, 0, 0.45)',
        }}
      />
      <Container position="relative">
        <Grid gap={16}>
          <Image
            src="/static/home/genkai_emaki_logo.png"
            alt="Genkai and Ronin logos"
            maxWidth={pxToRem(600)}
            width="100%"
            mx="auto"
          />
          <Center>
            <Flex direction={['column', 'row']} w="100%" maxWidth={pxToRem(750)} gap={[6, 10]}>
              <Flex flex={1}>
                <AppLink href={GENKAI_LANDING_ROUTES_ABSOLUTE.HOME} w="100%">
                  <Button variant="displayOutline" w="100%">
                    Explore Genkai
                  </Button>
                </AppLink>
              </Flex>
              <Flex flex={1}>
                <AppLink href={GENKAI_LANDING_ROUTES_ABSOLUTE.EMAKI.HOME} w="100%">
                  <Button variant="displayOutline" w="100%">
                    Emaki Dashboard
                  </Button>
                </AppLink>
              </Flex>
            </Flex>
          </Center>
        </Grid>
      </Container>
      <GradientShadowOverlay />
    </>
  );
}

function WelcomeSectionPnk({ muted }: { muted: boolean }) {
  return (
    <>
      <VideoAutoplay
        muted={muted}
        src="/static/home/pnk_trailer_short.mp4"
        posterExtension="jpg"
        sx={{
          ...fullSizeParentStyles,
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          ...fullSizeParentStyles,
          bg: 'rgba(0, 0, 0, 0.45)',
        }}
      />
      <Container position="relative">
        <Grid gap={16}>
          <Image
            src="/static/play_and_kollect/pnk_logo_white.png"
            alt="Genkai and Ronin logos"
            maxWidth={pxToRem(600)}
            width="100%"
            mx="auto"
          />
          <Flex
            direction={['column', 'row']}
            textAlign="center"
            justifyContent="center"
            gap={[6, 10]}
          >
            <AppLink href={LANDING_ROUTES.INTERACTIVE_ROADMAP}>
              <Button w="100%" variant="displayOutline">
                Interactive Roadmap
              </Button>
            </AppLink>
            <AppLink href={APP_ROUTES_ABSOLUTE.JUNGLE_ADVENTURE}>
              <Button w="100%" variant="displayOutline">
                Enter Play & Kollect
              </Button>
            </AppLink>
          </Flex>
        </Grid>
      </Container>
      <GradientShadowOverlay />
    </>
  );
}

function WelcomeSectionAlternateReality({ muted }: { muted: boolean }) {
  return (
    <>
      <VideoAutoplay
        muted={muted}
        src="/static/home/genkai_video.mp4"
        // src="/static/home/welcome_video.webm"
        // Fallback is 1440px
        // fallbackVideoExtension="mp4"
        posterExtension="jpg"
        sx={{
          ...fullSizeParentStyles,
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          ...fullSizeParentStyles,
          bg: 'rgba(0, 0, 0, 0.45)',
        }}
      />
      <Container position="relative">
        <Grid gap={8}>
          <Image
            src="/static/home/genkai_ronin_text.png"
            alt="Genkai and Ronin logos"
            maxWidth={pxToRem(800)}
            width="100%"
            mx="auto"
          />
          {/* <Heading variant="display" textAlign="center" maxWidth={['8em', null, '100%']} mx="auto">
            Welcome to an alternate reality
          </Heading> */}
          <Box textAlign="center">
            <AppLink href={SHARED_ROUTES.GENKAI}>
              <Button variant="display">The journey begins now</Button>
            </AppLink>
          </Box>
        </Grid>
      </Container>
      <GradientShadowOverlay />
    </>
  );
}

function WelcomeSectionSlider({ muted }: { muted: boolean }) {
  const { setAudio } = useUserSettings();

  useEffect(() => {
    setAudio(false);
  }, []);

  return (
    <SliderWrapper
      modules={[Pagination]}
      slidesPerView={1}
      pagination={{
        clickable: true,
      }}
      sx={{
        '.swiper-wrapper': {
          height: ['auto', '100vh'],
          minHeight: ['100vh', pxToRem(700)],
          position: 'relative',
          display: 'flex',
        },
        '.swiper-slide': {
          height: 'inherit',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.swiper-pagination': {
          bottom: pxToRem(60),
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
        },
        '.swiper-pagination-bullet': {
          w: pxToRem(10),
          h: pxToRem(10),
          borderRadius: 'none',
          opacity: 1,
          bg: 'rgba(255, 255, 255, 0.3)',
        },
        '.swiper-pagination-bullet-active': {
          bg: 'text',
        },
      }}
    >
      <SwiperSlide key={0}>
        <WelcomeSectionEmaki muted={muted} />
      </SwiperSlide>
      <SwiperSlide key={1}>
        <WelcomeSectionPnk muted={muted} />
      </SwiperSlide>
      <SwiperSlide key={2}>
        <WelcomeSectionAlternateReality muted={muted} />
      </SwiperSlide>
      <SwiperSlide key={3}>
        <Box
          sx={{
            ...fullSizeParentStyles,
            bg: 'url(/static/home/showcases/about_you/slider_bg.jpg) center / cover no-repeat',
          }}
        />
        <ShowcasePartnerLogoStatic
          partnerImage={<Image src={PROJECTS_BY_ID.about_you.image} alt="About You logo" />}
        />
      </SwiperSlide>
      <SwiperSlide key={4}>
        <Box
          sx={{
            ...fullSizeParentStyles,
            bg: 'url(/static/home/showcases/the_klaw/slider_bg.jpg) center / cover no-repeat',
          }}
        />
        <TheKlawIcon position="relative" maxWidth="70%" height="auto" />
      </SwiperSlide>
      <SwiperSlide key={5}>
        <VideoAutoplay
          // TO DO ADD FALLBACK
          src="/static/home/showcases/under_armour/video.webm"
          sx={{ ...fullSizeParentStyles, objectFit: 'cover' }}
        />
        <ShowcasePartnerLogoStatic
          partnerImage={<Image src={PROJECTS_BY_ID.under_armour.image} alt="Under Armour logo" />}
        />
      </SwiperSlide>
    </SliderWrapper>
  );
}

export function WelcomeVideoSection() {
  const { audioEnabled, toggleAudio } = useUserSettings();
  const [userInteracted, setUserInteracted] = useState(false);
  const [stage, setStage] = useState<WelcomSectionStage>('start');
  const [isMobileOrTouchDevice, setIsMobileOrTouchDevice] = useState<boolean>();
  const [inView, setInView] = useState(false);
  const isMuted = userInteracted && inView ? !audioEnabled : true;

  // hacky way for omitting restriction of media playing without user's interaction
  useEffect(() => {
    function handleClick() {
      setUserInteracted(true);
    }

    function handleResize() {
      setIsMobileOrTouchDevice(window.innerWidth < 768);
    }

    const isTouchDevice = typeof window !== 'undefined' && 'ontouchstart' in window;
    setIsMobileOrTouchDevice(isTouchDevice);

    document.addEventListener('click', handleClick, { once: true });
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      document.removeEventListener('click', handleClick);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key == ' ' || e.code == 'Space') {
        e.preventDefault();

        if (stage === 'start') {
          setStage('pnk');
        } else if (stage === 'pnk') {
          setStage('genkai');
        } else if (stage === 'genkai') {
          setStage('about_you');
        } else if (stage === 'about_you') {
          setStage('the_klaw');
        } else if (stage === 'the_klaw') {
          setStage('under_armour');
        } else if (stage === 'under_armour') {
          setStage('start');
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [stage]);

  useEffect(() => {
    function handleScroll() {
      const { scrollY, innerHeight } = window;

      if (scrollY < 0.9 * innerHeight) {
        if (!inView) {
          setInView(true);
        }
      } else if (inView) {
        setInView(false);
      }
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView]);

  return (
    <Flex
      sx={{
        display: isMobileOrTouchDevice === true ? 'block' : 'flex',
        width: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        overflow: 'hidden',
      }}
    >
      {isMobileOrTouchDevice !== undefined ? (
        isMobileOrTouchDevice ? (
          <WelcomeSectionSlider muted={isMuted} />
        ) : (
          <>
            {stage === 'start' ? <WelcomeSectionEmaki muted={isMuted} /> : null}
            {stage === 'pnk' ? <WelcomeSectionPnk muted={isMuted} /> : null}
            {stage === 'genkai' ? <WelcomeSectionAlternateReality muted={isMuted} /> : null}
            {stage === 'about_you' ? <ShowcaseAboutYou /> : null}
            {stage === 'the_klaw' ? <ShowcaseTheKlaw /> : null}
            {stage === 'under_armour' ? <ShowcaseUnderArmour /> : null}
            <Grid
              sx={{
                gridTemplateColumns: `${pxToRem(24)} 1fr ${pxToRem(24)}`,
                position: 'absolute',
                bottom: [pxToRem(38), 16],
                left: 0,
                w: `calc(100% - 50px)`,
                alignItems: 'center',
                fontSize: 'lg',
                svg: {
                  mx: 2,
                },
                zIndex: 1,
              }}
            >
              <Box />
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  visibility: isMobileOrTouchDevice ? 'hidden' : 'visible',
                }}
              >
                <Flex alignItems="center">
                  Press{' '}
                  <Button
                    size="sm"
                    variant="displayOutline"
                    pointerEvents="none"
                    cursor="default"
                    mx={4}
                  >
                    Spacebar
                  </Button>
                  to{' '}
                  {stage === 'start' ? 'start' : stage === 'under_armour' ? 'replay' : 'continue'}{' '}
                  the showcases or
                </Flex>
                <Flex alignItems="center">
                  &nbsp;scroll
                  <Box sx={{ svg: { w: 4 } }}>
                    <ArrowDownIcon />
                  </Box>
                  to explore
                </Flex>
              </Flex>
              <VolumeIcon audioEnabled={audioEnabled} toggleAudio={toggleAudio} />
            </Grid>
          </>
        )
      ) : null}
    </Flex>
  );
}
